<template>
  <div
    class="portfolio-banner"
    :class="{'phone-portfolio-banner' : mq.phone || mq.tablet, 'portfolio-banner-extended' : isMultiSelectionOptionsDisplayed}"
  >
    <Breadcrumb class="breadcrumb" />
    <div class="options">
      <div
        v-if="!mq.phone && !mq.tablet"
        class="separator"
      />
      <img
        class="add-option"
        src="@assets/icon_add_element.svg"
        alt="add element"
        @click="openAddMenu"
      >
      <div
        v-if="mq.phone || mq.tablet"
        class="separator"
      />
      <img
        v-if="mq.phone || mq.tablet"
        class="display-selection-option"
        src="@assets/icon_list.svg"
        alt="open multi-selection"
        @click="toggleMultiSelection"
      >
      <ContextMenu
        v-if="isContextMenuDisplayed && isAContextMenuDisplayed"
        @chooseOption="emitChosenOption"
        @close="isContextMenuDisplayed = false"
      />
    </div>
    <SelectionOptions
      v-if="(mq.phone || mq.tablet) && isMultiSelectionOptionsDisplayed"
      @close="closeMultiSelection"
    />
  </div>
</template>

<script>
import Breadcrumb from '@components/PortFolio/PortFolioBanner/Breadcrumb'
import contextMenus from '@utils/contextMenus'
import ContextMenu from '@components/ContextMenu/ContextMenu'
import SelectionOptions from '@components/PortFolio/PortFolioBanner/selectionOptions'

export default {
  name: 'PortFolioBanner',
  components: { SelectionOptions, ContextMenu, Breadcrumb },
  inject: ['mq'],
  emits: ['optionSelected'],
  data () {
    return {
      isContextMenuDisplayed: false
    }
  },
  computed: {
    isMultiSelectionOptionsDisplayed () {
      return this.$store.state.files.isMultiSelectionOptionsDisplayed
    },
    isAContextMenuDisplayed () {
      return this.$store.state.contextMenu.isAContextMenuDisplayed
    }
  },
  methods: {
    openAddMenu (event) {
      if (!this.isAContextMenuDisplayed) {
        this.isContextMenuDisplayed = true
        this.$store.dispatch('contextMenu/openContextMenu',
          {
            event: event,
            options: contextMenus.addNewDocument
          })
      }
    },
    closeMultiSelection () {
      this.$store.dispatch('files/closeMultiSelectionOptions')
      this.$store.dispatch('files/cleanSelectedFiles')
    },
    toggleMultiSelection () {
      if (this.isMultiSelectionOptionsDisplayed) {
        this.$store.dispatch('files/cleanSelectedFiles')
      }
      this.$store.dispatch('files/toggleMultiSelectionOptions')
    },
    emitChosenOption (option) {
      this.$emit('optionSelected', option)
      this.$store.dispatch('contextMenu/closeMenus')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.portfolio-banner {
  width: 100%;
  height: $portfolio-banner-height;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.portfolio-banner-extended {
  height: calc(#{$portfolio-banner-height} + #{$portfolio-multi-selection-height});
}

.phone-portfolio-banner {
  display: block;
  padding: 0;
  margin: auto;

  .options {
    width: 100%;
    justify-content: center;
  }

  .separator {
    margin: 0 15px;
  }
}

.options {
  display: flex;
  align-items: center;
}

.separator {
  height: 17px;
  width: 1px;
  border-right: 1px solid $color-cadyco-dark-text;
  margin-left: 40px;
  margin-right: 13px;
}

.add-option .display-selection-option{
  cursor: pointer;
  width: 30px;
  height: 30px;

  &:hover {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);
  }
}

</style>
