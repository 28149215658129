<template>
  <img
    :src="src"
    alt="unknown resource url"
    class="img"
  >
</template>

<script>
export default {
  name: 'ImageDocument',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .img{
    margin-top: 15%;
    display: block;
    max-width: 100%;
    max-height: 70%;
    width: auto;
    height: auto;
    border: 1px solid #0B3C5F;
  }
</style>
