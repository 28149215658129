<template>
  <div
    :draggable="isDraggable"
    :class="[computeClass, {'dark': dark}]"
    @dragstart="onDragStart"
    @dragend="onDragEnd"
  >
    <DesktopGridDocument
      v-if="(!mq.phone && !mq.tablet) && display==='grid'"
      :file="file"
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
      @click.exact="toggleSelection"
      @click.ctrl.exact="toggleCtrlSelection"
      @click.meta.exact="toggleCtrlSelection"
      @click.shift="toggleShiftSelection"
      @contextmenu.prevent="openContextMenu"
      @chooseOption="handleChosenOption"
    />
    <DesktopListDocument
      v-else-if="(!mq.phone && !mq.tablet) && display==='list'"
      :file="file"
      :display-date="displayDate"
      :is-hovering="isHovering"
      :quick-options="quickOptions"
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
      @click.exact="toggleSelection"
      @click.ctrl.exact="toggleCtrlSelection"
      @click.meta.exact="toggleCtrlSelection"
      @click.shift="toggleShiftSelection"
      @contextmenu.prevent="openContextMenu"
      @chooseOption="handleChosenOption"
    />
    <MobileGridDocument
      v-else-if="(mq.phone || mq.tablet) && display==='grid'"
      :file="file"
    />
    <MobileListDocument
      v-else-if="(mq.phone || mq.tablet) && display==='list'"
      :file="file"
      @displayFileOptions="openContextMenu"
    />
    <ContextMenu
      v-if="isContextMenuDisplayed && isAContextMenuDisplayed"
      @chooseOption="handleChosenOption"
      @close="isContextMenuDisplayed=false"
    />
    <Spinner
      v-if="displaySpinner"
      class="spinner"
      data-test="spinner"
    />

    <teleport to="body">
      <FilePickerModal
        v-if="isFilePickerModalDisplayed"
        height="30em"
        :folder-selection="true"
        :init-in-current-folder="true"
        @chosenFolder="doSelectFolderAction"
        @close="isFilePickerModalDisplayed = false"
      />
      <DeleteConfirmModal
        v-if="isDeleteDefinitelyModalDisplayed"
        :entities-to-definitely-delete="selectedFiles"
        @confirmDeletion="deleteDefinitely"
        @close="isDeleteDefinitelyModalDisplayed=false"
      />
    </teleport>
  </div>
</template>

<script>
import contextMenus from '@/utils/contextMenus'
import utils from '@/utils/utils'
import trashUtils from '@/utils/trash'

import DesktopGridDocument from '@/components/Documents/GenericDocument/DesktopGridDocument'
import DesktopListDocument from '@/components/Documents/GenericDocument/DesktopListDocument'
import MobileGridDocument from '@/components/Documents/GenericDocument/MobileGridDocument'
import MobileListDocument from '@/components/Documents/GenericDocument/MobileListDocument'
import ContextMenu from '@/components/ContextMenu/ContextMenu'
import DeleteConfirmModal from '@/components/SpaceTrash/DeleteConfirmModal'
import FilePickerModal from '@components/FilePicker/FilePickerModal'

export default {
  name: 'GenericDocument',
  components: {
    FilePickerModal,
    DeleteConfirmModal,
    ContextMenu,
    MobileListDocument,
    MobileGridDocument,
    DesktopListDocument,
    DesktopGridDocument
  },
  inject: ['mq'],
  props: {
    file: {
      type: Object,
      required: true,
      validator: function (obj) {
        return (typeof obj.id === 'string') &&
               (typeof obj.name === 'string' && obj.name.length > 0) &&
               (typeof obj.icon === 'string' && obj.icon.length > 0)
      }
    },
    display: { // TODO validator on enumerate type
      type: String,
      default: 'grid'
    },
    displayDate: {
      type: Boolean,
      default: true
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    quickOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    dark: {
      type: Boolean,
      default: true
    }
  },
  emits: ['open', 'shiftSelect'],
  data () {
    return {
      isHovering: false,
      isContextMenuDisplayed: false,
      isDeleteDefinitelyModalDisplayed: false,
      isFilePickerModalDisplayed: false,
      folderSelectionOption: '',
      displaySpinner: false
    }
  },
  computed: {
    isAContextMenuDisplayed () {
      return this.$store.state.contextMenu.isAContextMenuDisplayed
    },
    computeClass () {
      if (this.isDragged) {
        return 'is-dragging'
      } else {
        if (this.isSelected) {
          return 'selected'
        } else {
          if (this.isHovering) {
            return 'hovering'
          } else {
            return 'document'
          }
        }
      }
    },
    currentSpace () {
      return this.$route.name
    },
    isInTrashRootFolder () {
      return this.$store.state.files.currentFolderId === this.$store.state.trashFilesManager.trashLoadedTree[0].id
    },
    selectedFiles () {
      return this.$store.state.files.selectedFiles
    },
    draggedFiles () {
      return this.$store.state.misc.draggedEntities
    },
    isSelected () {
      for (let i = 0; i < this.selectedFiles.length; ++i) {
        if (this.file.id === this.selectedFiles[i].id) {
          return true
        }
      }
      return false
    },
    isDragged () {
      for (let i = 0; i < this.draggedFiles.length; ++i) {
        if (this.file.id === this.draggedFiles[i].id) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    onDragStart (e) {
      if (!this.isSelected) {
        this.toggleSelection()
      }
      let entitiesToDrag
      if (this.selectedFiles.length > 1) {
        entitiesToDrag = this.selectedFiles
      } else {
        entitiesToDrag = [this.file]
      }
      e.dataTransfer.setData('entitiesToDrop', JSON.stringify(entitiesToDrag))
      this.$store.dispatch('misc/addDraggedEntities', entitiesToDrag)
    },
    onDragEnd () {
      this.$store.dispatch('misc/removeDraggedEntities')
    },
    openContextMenu (e) {
      if (!this.isSelected) {
        this.toggleSelection()
      }

      const listCM = []
      // for each selected file, get context menu associated and add him to list
      for (let i = 0; i < this.selectedFiles.length; ++i) {
        let documentContextMenu
        const document = this.selectedFiles[i]

        if (document.type === 'Folder') {
          documentContextMenu = utils.cloneArrayOfObject(contextMenus.privateSpaceFolderMenu)
        } else {
          documentContextMenu = utils.cloneArrayOfObject(contextMenus.privateSpaceFileMenu)
        }

        listCM.push(documentContextMenu)
      }

      // Compute the union of context menus
      const contextMenu = utils.mergeContextMenus(listCM)

      // Remove some options depending of context
      if (this.$store.state.clipboard.documentList.length === 0) { // remove paste option if no documents were copied
        utils.removeMenuOptionIfExist(contextMenu, 'paste')
      }

      // multi selection
      if (listCM.length > 1) {
        utils.removeMenuOptionIfExist(contextMenu, 'showData')
        utils.removeMenuOptionIfExist(contextMenu, 'paste')
        utils.removeMenuOptionIfExist(contextMenu, 'open')
        utils.removeMenuOptionIfExist(contextMenu, 'download')
        utils.removeMenuOptionIfExist(contextMenu, 'rename')
        utils.removeMenuOptionIfExist(contextMenu, 'comment')
        utils.removeMenuOptionIfExist(contextMenu, 'share')
      }

      this.isContextMenuDisplayed = true
      this.$store.dispatch('contextMenu/openContextMenu', { event: e, options: contextMenu })
    },
    handleChosenOption (option) {
      switch (option) {
        case 'open':
          this.$emit('open')
          break
        case 'copy':
          this.$store.dispatch('clipboard/copy')
          break
        case 'cut':
          this.$store.dispatch('clipboard/cut')
          break
        case 'duplicate':
          this.folderSelectionOption = 'duplicate'
          this.isFilePickerModalDisplayed = true
          break
        case 'move':
          this.folderSelectionOption = 'move'
          this.isFilePickerModalDisplayed = true
          break
        case 'paste':
          this.$store.dispatch('clipboard/paste', this.file.id)
          break
        case 'delete':
          this.$store.dispatch('warningModal/addWarning', {
            text: this.$t('router.views.Documents.Warning.deleteDocument'),
            lastAction: { fct: trashUtils.deleteDefinitely, params: [this.selectedFiles] }
          })
          break
        case 'deleteDefinitely':
          if (!this.isSelected) {
            this.toggleSelection()
          }
          this.isDeleteDefinitelyModalDisplayed = true
          break
        case 'download':
          this.downLoadDocument(this.file)
          break
        case 'rename':
          this.$store.dispatch('modals/openRenameModal', this.selectedFiles[0])
          break
        case 'share':
          this.$store.dispatch('post/setIndicator', undefined)
          this.$store.dispatch('post/setFile', this.file)
          this.$store.dispatch('modals/openCreatePostModal')
          break
        default:
          console.error('unknown action for option', option)
      }
      this.isContextMenuDisplayed = false
      this.$store.dispatch('contextMenu/closeMenus')
    },
    // from trash
    deleteDefinitely () {
      this.isDeleteDefinitelyModalDisplayed = false
      trashUtils.deleteDefinitely(this.$store, this.selectedFiles)
    },
    restore (mode) {
      trashUtils.restore(this.$store, this.selectedFiles, mode)
    },
    downLoadDocument (entity) {
      this.displaySpinner = true
      utils.downLoadDocument(entity).then(() => {
        this.displaySpinner = false
      })
    },
    doSelectFolderAction (targetFolder) {
      if (this.folderSelectionOption === 'move') {
        this.$store.dispatch('clipboard/move', targetFolder)
      } else if (this.folderSelectionOption === 'duplicate') {
        this.$store.dispatch('clipboard/duplicate', targetFolder)
      } else {
        console.error('Unknown option' + this.folderSelectionOption)
      }
    },
    toggleSelection () {
      return new Promise((resolve) => {
        this.$store.dispatch('files/updateLastClickDocument', this.file)
        if (!this.mq.phone) { // no selection on mobile
          this.$store.dispatch('files/selectOneFile', this.file).then(() => {
            resolve()
          })
        } else {
          resolve()
        }
      })
    },
    toggleCtrlSelection () {
      this.$store.dispatch('files/updateLastClickDocument', this.file)
      if (!this.mq.phone) { // no selection on mobile
        this.$store.dispatch('files/updateCtrlSelectedFiles', this.file)
      }
    },
    toggleShiftSelection () {
      this.$store.dispatch('files/updateLastClickDocument', this.file)
      if (!this.mq.phone) { // no selection on mobile
        this.$emit('shiftSelect', { id: this.file.id, name: this.file.name })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@design';

  .document {
    background-color: white;
  }

  .dark {
    background-color: $dark-white-bg;
  }

 .is-dragging {
    color: $color-selected-bg;
    background-color : $color-menu-hover-bg;
  }

  .hovering {
    background-color : $color-menu-hover-bg;
  }

  .selected {
    background-color : $color-border-menu;
  }

</style>
