<template>
  <div class="desktop-list-document">
    <div
      v-if="isInCurrentsFields('name')"
      class="name"
    >
      <div class="icon-container">
        <img
          v-if="fileIconIsImage"
          class="img-icon"
          :src="file.icon"
          alt="document icon"
        >
        <BaseIcon
          v-else
          class="base-icon"
          :name="[iconPrefix, file.icon]"
        />
      </div>
      <div class="name-container">
        <p
          class="name-label"
          :title="file.name"
          :class="{'cao-name': file.extension === 'cao'}"
        >
          {{ file.name }}
        </p>
        <p
          v-if="file.extension !== 'cao'"
          class="size-label"
        >
          {{ formattedSize }}
        </p>
      </div>
    </div>
    <div class="right-section">
      <div
        v-if="displayQuickOptions"
        class="quick-options"
      >
        <div
          class="img-container"
          @click.stop="toggleQuickOptions"
        >
          <img
            src="@assets/icon_engrenage.svg"
            alt="document-options"
          >
        </div>
        <div
          v-if="quickOptionsDisplayed"
          class="options"
        >
          <BaseIcon
            v-for="option in quickOptions"
            :key="option"
            class="option"
            :name="$options.myIcons.options[option]"
            @click.stop="quickOptionClick(option)"
          />
        </div>
      </div>
      <div
        v-if="displayQuickOptions"
        class="separator"
      />
      <div
        v-if="displayDate && isInCurrentsFields('date')"
        class="date"
      >
        <p>
          {{ formattedDate }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { icons } from '@/constants/icons'
import formatters from '@/utils/formatters.util'
import moment from 'moment'
import BaseIcon from '@components/Base/BaseIcon'

export default {
  name: 'DesktopListDocument',
  components: { BaseIcon },
  myIcons: icons,
  props: {
    file: {
      type: Object,
      required: true,
      validator: function (obj) {
        return (typeof obj.id === 'string') &&
          (typeof obj.name === 'string' && obj.name.length > 0) &&
          (typeof obj.icon === 'string' && obj.icon.length > 0)
      }
    },
    displayDate: {
      type: Boolean,
      default: true
    },
    isHovering: {
      type: Boolean,
      required: true,
      default: true
    },
    quickOptions: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  emits: ['chooseOption'],
  data () {
    return {
      displayQuickOptions: false,
      quickOptionsDisplayed: false
    }
  },
  computed: {
    fileIconIsImage () {
      // TODO: find a better way to separate img and font-awesome icons
      return this.file.icon.includes('.') || this.file.icon.includes(':') // if icon contains extension (like folder.svg) it's not a font-awesome
    },
    currentSpace () {
      return this.$route.name
    },
    fields () {
      return this.$store.state.fileFields.privateFields
    },
    fieldsToDisplay () {
      const fieldsToDisplay = []
      for (let i = 0; i < this.fields.length; ++i) {
        if (this.fields[i].isSelected) {
          fieldsToDisplay.push(this.fields[i])
        }
      }
      return fieldsToDisplay
    },
    formattedDate () {
      return moment(this.file.lastModifiedDate, 'YYYY-MM-DD HH:mm:ss').calendar()
    },
    formattedSize () {
      return formatters.formatSize(this.file.size)
    },
    iconPrefix () {
      if (this.file.type === 'File') {
        if (this.file.icon === 'code') {
          return 'fas'
        } else {
          return 'far'
        }
      } else {
        return 'fas'
      }
    }
  },
  methods: {
    toggleQuickOptions () {
      this.quickOptionsDisplayed = !this.quickOptionsDisplayed
    },
    quickOptionClick (option) {
      this.toggleQuickOptions()
      this.$emit('chooseOption', option)
    },
    isInCurrentsFields (name) {
      for (let i = 0; i < this.fieldsToDisplay.length; ++i) {
        if (this.fieldsToDisplay[i].name === name) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.desktop-list-document{
  display: flex;
  align-items: center;
  height: 74px;
  padding: 0 20px;

  ///* disable text selection on documents (not convenient when shift-select) */
  //-ms-user-select: none;
  //-moz-user-select: none;
  //-webkit-user-select: none;
  //user-select: none; /* CSS3 (little to no support) */

  .name{
    display: flex;
    flex: 1;
    min-width: 0;
    align-items: center;

    .icon-container{
      width: 30px;
      margin-right: 10px;
      display: flex;
      justify-content: center;

      .img-icon{
        width: 25px;
        height: 25px;
      }

      .base-icon{
        font-size: 23px;
        color: #777777;
      }
    }

    .name-container {
      height: 100%;
      width: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name-label {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.9375em;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-bottom: 2px;

        &.cao-name{
          margin: 0;
        }
      }

      .size-label {
        font-size: 0.6875em;
        letter-spacing: 0;
        line-height: 16px;
      }
    }
  }

  .right-section{
    margin-left: auto;
    display: flex;
    align-items: center;

    .quick-options {
      position: relative;

      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        height: 33px;
        border-radius: 17px;
        background-color: $dark-white-bg;
        cursor: pointer;

        img{
          width: 15px;
        }

        &:hover {
          background-color: $color-hover-cadyco;
        }
      }

      .options {
        position: absolute;
        right: 0;
      }
    }

    .separator {
      height: 13px;
      width: 1px;
      border-right: 1px solid $color-cadyco-dark-text;
      margin: 0 20px;
    }

    .date {
      font-size: 0.75em;
      width: 75px;
      letter-spacing: 0;
      line-height: 16px;

      p {
        text-align: end;
      }
    }
  }
}

</style>
