<template>
  <ImageDocument
    v-if="isLoaded && typeOfView === 'Image'"
    :src="fileUrl"
  />
  <VideoDocument
    v-else-if="isLoaded && typeOfView === 'Video'"
    :src="fileUrl"
  />
  <AudioDocument
    v-else-if="isLoaded && typeOfView === 'Audio'"
    :src="fileUrl"
  />
  <PDFDocument
    v-else-if="isLoaded && typeOfView === 'PDF'"
    :src="fileUrl"
    class="pdf"
  />
  <!-- Cadyco object -->
  <CadycoDocument
    v-else-if="isLoaded && typeOfView === 'CAO'"
  />
  <OtherDocument
    v-else-if="isLoaded && typeOfView === 'Other'"
    :src="fileUrl"
  />
</template>

<script>
import mediaService from '@/api/documents/media.service'
import AudioDocument from '@/components/Documents/DisplayDocument/AudioDocument'
import CadycoDocument from '@/components/Documents/DisplayDocument/CadycoDocument'
import ImageDocument from '@/components/Documents/DisplayDocument/ImageDocument'
import OtherDocument from '@/components/Documents/DisplayDocument/OtherDocument'
import PDFDocument from '@/components/Documents/DisplayDocument/PDFDocument'
import VideoDocument from '@/components/Documents/DisplayDocument/VideoDocument'

export default {
  name: 'DisplayFile',
  components: {
    AudioDocument,
    CadycoDocument,
    ImageDocument,
    OtherDocument,
    PDFDocument,
    VideoDocument
  },
  props: {
    file: {
      type: Object,
      required: true,
      validator: function (obj) {
        return (typeof obj.id === 'string') &&
               (typeof obj.name === 'string' && obj.name.length > 0)
      }
    }
  },
  emits: ['error'],
  data () {
    return {
      typeOfView: undefined,
      fileUrl: undefined,
      object: undefined,
      isLoaded: false
    }
  },
  computed: {
    selectedCadycoFiles () {
      return this.$store.state.files.selectedCadycoFiles
    },
    displayedCadycoFiles () {
      return this.$store.state.files.displayedCadycoFiles
    }
  },
  watch: {
    // Load the resource when the current tab is active for the first time
    file: {
      immediate: true,
      handler () {
        this.loadMedia()
      }
    }
  },
  methods: {
    loadMedia () {
      const versionId = this.file.versionId ? this.file.versionId : 0 // for the backend type consistency
      mediaService.getMediaUrl(this.file.id, versionId).then(async (data) => {
        if (data.success) {
          this.typeOfView = data.typeOfView
          this.fileUrl = data.fileUrl

          if (this.typeOfView === 'CAO') { // is this case, add the data of all cadycoFiles selected in store
            // Load data of all non-loaded Cadyco Files
            for (const file of this.selectedCadycoFiles) {
              if (!this.isFileInCadycoList(file, this.displayedCadycoFiles)) {
                if (file.id === this.file.id) { // we already have load those data
                  this.$store.dispatch('files/addDisplayedCadycoFile', data.object)
                } else {
                  await mediaService.getMediaUrl(file.id, 0).then((data) => {
                    this.$store.dispatch('files/addDisplayedCadycoFile', data.object)
                  })
                }
              }
            }
          }
          this.isLoaded = true
        } else {
          // An error was occurred, close the tab and display error modal
          this.$emit('error')
          this.$store.dispatch('error/setErrorType', data.error) // UnsupportedFileExtension or invalidFile
          this.$store.dispatch('error/setListFilesConcerns', [this.file])
        }
      })
    },
    isFileInCadycoList (file, cadycoDataList) {
      for (const data of cadycoDataList) {
        if (file.id === data.fileId) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
