import trashService from '@/api/documents/trash.service'
import store from '@store/index.js'
import utils from '@/utils/utils'

export default {
  deleteDefinitely,
  emptyTrash,
  restore
}

function emptyTrash (store, forceReload = false) {
  return new Promise((resolve) => {
    store.dispatch('currentActions/addAction', { name: 'emptyTrash' })
    trashService.emptyTrash().then((data) => {
      store.dispatch('currentActions/removeAction', { name: 'emptyTrash' })
      if (data.success) {
        if (store.state.trashFilesManager.trashLoadedTree[0] !== undefined) {
          store.dispatch('trashFilesManager/updateTrashLoadedTree', {
            node: store.state.trashFilesManager.trashLoadedTree[0].id,
            entities: { subFolders: [], files: [] }
          })
        }
      } else {
        console.error('cannot empty content of trash folder')
      }
      resolve()
    })
  })
}

function deleteDefinitely (selectedEntities) {
  return new Promise((resolve) => {
    let isCurrentFolderConcern = false
    const listFoldersToDelete = []
    const listFoldersIdToDelete = []
    const listFilesToDelete = []
    const listFilesIdToDelete = []
    for (let i = 0; i < selectedEntities.length; ++i) {
      if (selectedEntities[i].type === 'Folder') {
        listFoldersToDelete.push(selectedEntities[i])
        listFoldersIdToDelete.push(selectedEntities[i].id)
        if (selectedEntities[i].id === store.state.files.currentFolderId) {
          isCurrentFolderConcern = true
        }
      } else if (selectedEntities[i].type === 'File') {
        listFilesToDelete.push(selectedEntities[i])
        listFilesIdToDelete.push(selectedEntities[i].id)
      }
    }
    store.dispatch('files/closeDocumentPanel')
    store.commit('files/cleanSelectedFiles')
    store.dispatch('currentActions/addAction', { name: 'deleteDefinitely' })
    trashService.deleteDefinitely(listFoldersIdToDelete, listFilesIdToDelete).then((data) => {
      store.dispatch('currentActions/removeAction', { name: 'deleteDefinitely' })
      let failedEntities = []
      if (data.success) {
        if (data.failedEntitiesList.length !== 0) {
          failedEntities = [
            ...utils.findEntitiesFromArray(listFoldersToDelete, data.failedEntitiesList),
            ...utils.findEntitiesFromArray(listFilesToDelete, data.failedEntitiesList)
          ]
          console.error('Unable to delete entities from trash:', data.failedEntitiesList)
          store.dispatch('error/setErrorType', 'deleteDefinitely')
          store.dispatch('error/setListFilesConcerns', failedEntities)
          utils.removeEntitiesFromArray(selectedEntities, failedEntities)
        }

        if (isCurrentFolderConcern) {
          store.dispatch('files/getSpacesFolders')
        } else {
          store.dispatch('files/refreshCurrentFolder')
        }
      } else {
        store.dispatch('files/selectManyFiles', selectedEntities)
        console.error('cannot empty content of trash folder')
      }
      resolve()
    })
  })
}

function restore (store, entitiesToRestore, mode) {
  const listFoldersToRestore = []
  const listFoldersIdToRestore = []
  const listFilesToRestore = []
  const listFilesIdToRestore = []
  for (let i = 0; i < entitiesToRestore.length; ++i) {
    if (entitiesToRestore[i].type === 'Folder') {
      listFoldersToRestore.push(entitiesToRestore[i])
      listFoldersIdToRestore.push(entitiesToRestore[i].id)
    } else if (entitiesToRestore[i].type === 'File') {
      listFilesToRestore.push(entitiesToRestore[i])
      listFilesIdToRestore.push(entitiesToRestore[i].id)
    }
  }
  store.commit('files/cleanSelectedFiles')
  store.dispatch('currentActions/addAction', { name: 'restore' })
  trashService.restore(listFoldersIdToRestore, listFilesIdToRestore, mode).then((data) => {
    store.dispatch('currentActions/removeAction', { name: 'restore' })
    let failedEntities = []
    if (data.success) {
      if (data.failedEntitiesList.length !== 0) {
        failedEntities = [
          ...utils.findEntitiesFromArray(listFoldersToRestore, data.failedEntitiesList),
          ...utils.findEntitiesFromArray(listFilesToRestore, data.failedEntitiesList)
        ]
        console.error('Unable to delete entities from trash:', data.failedEntitiesList)
        store.dispatch('error/setErrorType', 'deleteDefinitely')
        store.dispatch('error/setListFilesConcerns', failedEntities)
        store.dispatch('monDrive/openErrorModal')
        utils.removeEntitiesFromArray(entitiesToRestore, failedEntities)
      }
    } else {
      console.error('Error when trying to restore document:', listFoldersToRestore, listFilesToRestore)
      store.dispatch('files/selectManyFiles', entitiesToRestore)
    }
  })
}
