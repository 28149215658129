<template>
  <div class="mobile-list-document">
    <div
      v-if="isMultiSelectionOptionsDisplayed"
      class="selected-icon"
    >
      <div class="oval">
        <div
          v-if="isSelected"
          class="marked"
        />
      </div>
    </div>
    <div
      class="name"
      :class="{'select-mode': isMultiSelectionOptionsDisplayed}"
    >
      <div class="icon-container">
        <img
          v-if="fileIconIsImage"
          class="img-icon"
          :src="file.icon"
          alt="document icon"
        >
        <BaseIcon
          v-else
          class="base-icon"
          :name="[iconPrefix, file.icon]"
        />
      </div>
      <span
        :id="'mobileListDocument-' + file.id"
        class="file-name"
        :class="{ 'new': file.isNew, 'tablet': mq.tablet }"
      >
        {{ file.name }}
      </span>
    </div>
    <span class="date">
      {{ formattedDate }}
    </span>
  </div>
</template>

<script>

import moment from 'moment'
import BaseIcon from '@components/Base/BaseIcon'

export default {
  name: 'MobileListDocument',
  components: { BaseIcon },
  inject: ['mq'],
  props: {
    file: {
      type: Object,
      required: true,
      validator: function (obj) {
        return (typeof obj.id === 'string') &&
          (typeof obj.name === 'string' && obj.name.length > 0) &&
          (typeof obj.icon === 'string' && obj.icon.length > 0)
      }
    }
  },
  emits: ['displayFileOptions'],
  computed: {
    isAContextMenuDisplayed () {
      return this.$store.state.contextMenu.isAContextMenuDisplayed
    },
    isMultiSelectionOptionsDisplayed () {
      return this.$store.state.files.isMultiSelectionOptionsDisplayed
    },
    selectedFiles () {
      return this.$store.state.files.selectedFiles
    },
    isSelected () {
      for (let i = 0; i < this.selectedFiles.length; ++i) {
        if (this.file.id === this.selectedFiles[i].id) {
          return true
        }
      }
      return false
    },
    formattedDate () {
      return moment(this.file.lastModifiedDate, 'YYYY-MM-DD HH:mm:ss').calendar()
    },
    fileIconIsImage () {
      // TODO: find a better way to separate img and font-awesome icons
      return this.file.icon.includes('.') || this.file.icon.includes(':') // if icon contains extension (like folder.svg) it's not a font-awesome
    },
    iconPrefix () {
      if (this.file.type === 'File') {
        if (this.file.icon === 'code') {
          return 'fas'
        } else {
          return 'far'
        }
      } else {
        return 'fas'
      }
    }
  },
  methods: {
    displayFileOptions (e) {
      // like we stop the event, the subMenu cannot detect that we will close him
      if (this.isAContextMenuDisplayed) {
        this.$store.dispatch('contextMenu/closeMenus')
      } else {
        this.$store.dispatch('files/selectOneFile', this.file)
        this.$emit('displayFileOptions', e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

  .mobile-list-document{
    display: flex;
    align-items: center;
    color: $color-cadyco-dark-text;
    height: $mobile-file-height;
  }

  .selected-icon {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .oval {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      border: 1px solid #D9E2EA;
      background-color: #F3F6F8;

      .marked {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background-color: #0B3C5F;
      }
    }
  }

  .name {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex: 1;
    min-width: 0;
    align-items: center;

    &.select-mode {
      margin-left: 0;
    }

    .icon-container {
      width: 30px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .img-icon {
        width: 25px;
        height: 25px;
      }

      .base-icon {
        font-size: 23px;
        color: #777777;
      }
    }

    .file-name {
      flex: 1;
      max-width: calc(100% - (25px + 10px)); /* icon container + margin */
      font-weight: 600;
      letter-spacing: 0;
      font-size: 1.125em;
      line-height: 1.125em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.tablet {
        font-size: 1em;
      }
    }
  }

  .date {
    font-size: 0.875em;
    margin-right: 20px;
    margin-left: auto;
  }

  .new{
    font-weight: bold;
  }

</style>
