<template>
  <div
    class="selection-options"
    :class="{'without-margin' : !isSelectAllOptionDisplayed}"
  >
    <div
      v-if="isSelectAllOptionDisplayed"
      class="select-all"
      @click="handleSelection"
    >
      <div class="oval">
        <div
          v-if="selectedFiles.length > 0"
          class="marked"
        />
      </div>
    </div>
    <div class="options">
      <SelectionOptionItem
        v-for="(option, index) in optionList"
        :key="index"
        :option="option"
        :is-active="isOptionAvailable(option)"
        @selectOption="handleOption(option)"
      />
    </div>
  </div>
  <teleport to="body">
    <FilePickerModal
      v-if="isFilePickerModalDisplayed"
      height="30em"
      :folder-selection="true"
      :init-in-current-folder="false"
      :header="$t('ContextMenuOptions.' + folderSelectionOption)"
      @chosenFolder="doSelectFolderAction"
      @close="isFilePickerModalDisplayed = false"
    />
  </teleport>
</template>

<script>
import contextMenus from '@/utils/contextMenus'
import utils from '@utils/utils'
import SelectionOptionItem from '@components/PortFolio/PortFolioBanner/selectionOptionItem'
import trashUtils from '@utils/trash'
import FilePickerModal from '@components/FilePicker/FilePickerModal'

export default {
  name: 'SelectionOptions',
  components: { FilePickerModal, SelectionOptionItem },
  props: {
    isSelectAllOptionDisplayed: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close'],
  data () {
    return {
      optionList: [],
      isFilePickerModalDisplayed: false,
      folderSelectionOption: ''
    }
  },
  computed: {
    selectedFiles () {
      return this.$store.state.files.selectedFiles
    },
    optionsToDisplay () {
      const listCM = []
      // for each selected file, get context menu associated and add him to list
      for (let i = 0; i < this.selectedFiles.length; ++i) {
        let documentContextMenu
        const document = this.selectedFiles[i]

        if (document.type === 'Folder') {
          documentContextMenu = [...contextMenus.privateSpaceFolderMenu]
        } else {
          documentContextMenu = [...contextMenus.privateSpaceFileMenu]
        }

        listCM.push(documentContextMenu)
      }

      // Compute the union of context menus
      const contextMenu = utils.mergeContextMenus(listCM)

      // No paste on this toolbar
      utils.removeMenuOptionIfExist(contextMenu, 'paste')

      // multi selection
      if (listCM.length > 1) {
        utils.removeMenuOptionIfExist(contextMenu, 'showData')
        utils.removeMenuOptionIfExist(contextMenu, 'open')
        utils.removeMenuOptionIfExist(contextMenu, 'download')
        utils.removeMenuOptionIfExist(contextMenu, 'rename')
        utils.removeMenuOptionIfExist(contextMenu, 'comment')
        utils.removeMenuOptionIfExist(contextMenu, 'share')
      }

      return contextMenu
    }
  },
  created () {
    this.optionList = [...contextMenus.privateSpaceFileMenu] // Create a copy to avoid problems
  },
  methods: {
    isOptionAvailable (option) {
      // Return true if the option is in the optionsToDisplay list
      return this.optionsToDisplay.find(e => e.name === option.name) !== undefined
    },
    handleSelection () {
      if (this.selectedFiles.length > 0) {
        this.$store.dispatch('files/cleanSelectedFiles')
      } else {
        this.$store.dispatch('files/selectAllFiles')
      }
    },
    handleOption (option) {
      switch (option.name) {
        case 'rename':
          this.$store.dispatch('modals/openRenameModal', this.selectedFiles[0])
          this.close()
          break
        case 'duplicate':
          this.folderSelectionOption = 'duplicate'
          this.isFilePickerModalDisplayed = true
          break
        case 'move':
          this.folderSelectionOption = 'move'
          this.isFilePickerModalDisplayed = true
          break
        case 'download':
          utils.downLoadDocument(this.selectedFiles[0])
          this.close()
          break
        case 'share':
          this.$store.dispatch('post/setIndicator', undefined)
          this.$store.dispatch('post/setFile', this.selectedFiles[0]) // Todo: only on CadycoFiles ?
          this.$store.dispatch('modals/openCreatePostModal')
          this.close()
          break
        case 'delete':
          this.$store.dispatch('warningModal/addWarning', {
            text: this.$t('router.views.Documents.Warning.deleteDocument'),
            lastAction: { fct: trashUtils.deleteDefinitely, params: [this.selectedFiles] }
          })
          this.close()
          break
        default:
          console.error('unknown action for option', option)
      }
    },
    doSelectFolderAction (targetFolder) {
      if (this.folderSelectionOption === 'move') {
        this.$store.dispatch('clipboard/move', targetFolder)
      } else if (this.folderSelectionOption === 'duplicate') {
        this.$store.dispatch('clipboard/duplicate', targetFolder)
      } else {
        console.error('Unknown option' + this.folderSelectionOption)
      }
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.selection-options {
  margin-top: 20px;
  height: calc(#{$portfolio-multi-selection-height} - 20px);
  background-color: $color-cadyco-dark-text;
  display: flex;
  align-items: center;

  .select-all {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .oval {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 20px;
      width: 20px;
      border: 1px solid white;
      border-radius: 10px;
      background-color: transparent;

      .marked {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background-color: white;
      }
    }
  }

  .options {
    flex:1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.without-margin {
  margin-top: 0;
  height: calc(#{$portfolio-multi-selection-height} - 20px);
}

</style>
