<template>
  <Layout>
    <div
      class="portfolio"
      :class="mq.phone ? 'phone-portfolio' : ''"
      oncontextmenu="return false;"
    >
      <Split
        v-if="(!mq.phone && ! mq.tablet)"
        ref="mySplit"
      >
        <SplitArea
          :size="isDocumentPanelDisplayed ? 50 : 100"
          :min-size="433"
        >
          <PortFolioBanner @optionSelected="handleChosenOption" />
          <div
            class="documents-body"
            :class="{'documents-body-shrink' : isMultiSelectionOptionsDisplayed}"
          >
            <FilePickerArea
              id="VueFilePicker"
              @fileAdded="importDocument"
            >
              <section
                class="document-list"
                @click.right.prevent="openSpaceContextMenu"
              >
                <FilesFields
                  :current-sort="sort"
                  @handleSort="handleSort"
                />
                <div class="entities">
                  <FolderDocument
                    v-for="folder in sortedSubFolders"
                    :key="folder.id"
                    :folder="folder"
                    :quick-options="spacesStructure.private.documentQuickOptions"
                    :dark="getEntityIndex(folder.id) % 2 === 0"
                    :is-draggable="true"
                    @shiftSelect="shiftSelect"
                  />
                  <FileDocument
                    v-for="file in sortedFiles"
                    :key="file.id"
                    :file="file"
                    :quick-options="spacesStructure.private.documentQuickOptions"
                    :dark="getEntityIndex(file.id) % 2 === 0"
                    :is-draggable="true"
                    @shiftSelect="shiftSelect"
                  />
                  <div
                    v-if="mq.desktop"
                    class="drop-icon"
                  >
                    <div
                      class="icon-container"
                      @click="importDocumentFromWorkSpace"
                    >
                      <img
                        src="@assets/options/icon_fichier_ajout.svg"
                        alt="drop icon"
                      >
                    </div>
                    <p v-t="'router.views.Documents.dropLabel'" />
                  </div>
                </div>
                <Spinner v-if="isSpinnerDisplayed" />
              </section>
            </FilePickerArea>
          </div>
        </SplitArea>
        <SplitArea
          v-if="isDocumentPanelDisplayed"
          :size="50"
          :min-size="200"
        >
          <DocumentPanel
            :document="selectedFile"
            class="document-panel"
          />
        </SplitArea>
      </Split>
      <div
        v-else
        class="phone-split"
      >
        <PortFolioBanner @optionSelected="handleChosenOption" />
        <div
          class="documents-body"
          :class="{'documents-body-shrink' : isMultiSelectionOptionsDisplayed}"
        >
          <FilePickerArea
            id="VueFilePicker"
            @fileAdded="importDocument"
          >
            <section
              class="document-list"
              @click.right.prevent="openSpaceContextMenu"
            >
              <FilesFields
                :current-sort="sort"
                @handleSort="handleSort"
              />
              <div class="entities">
                <FolderDocument
                  v-for="folder in sortedSubFolders"
                  :key="folder.id"
                  :folder="folder"
                  :quick-options="spacesStructure.private.documentQuickOptions"
                  :dark="getEntityIndex(folder.id) % 2 === 0"
                  :is-draggable="true"
                  @shiftSelect="shiftSelect"
                />
                <FileDocument
                  v-for="file in sortedFiles"
                  :key="file.id"
                  :file="file"
                  :quick-options="spacesStructure.private.documentQuickOptions"
                  :dark="getEntityIndex(file.id) % 2 === 0"
                  :is-draggable="true"
                  @shiftSelect="shiftSelect"
                />
                <div
                  v-if="mq.desktop"
                  class="drop-icon"
                >
                  <div
                    class="icon-container"
                    @click="importDocumentFromWorkSpace"
                  >
                    <img
                      src="@assets/options/icon_fichier_ajout.svg"
                      alt="drop icon"
                    >
                  </div>
                  <p v-t="'router.views.Documents.dropLabel'" />
                </div>
              </div>
              <Spinner v-if="isSpinnerDisplayed" />
            </section>
          </FilePickerArea>
        </div>
        <teleport
          v-if="isDocumentPanelDisplayed"
          to="body"
        >
          <DocumentPanel
            :document="selectedFile"
            class="phone-document-panel"
            :class="{'tablet-document-panel': mq.tablet}"
          />
        </teleport>
      </div>
    </div>
    <ContextMenu
      v-if="isContextMenuDisplayed && isAContextMenuDisplayed"
      @chooseOption="handleChosenOption"
      @close="isContextMenuDisplayed=false"
    />
  </Layout>
</template>

<script>
import utils from '@utils/utils'
import filesUtil from '@utils/files.utils'
import spacesStructure from '@/utils/spaces.structure'
import contextMenus from '@utils/contextMenus'

import Layout from '@/router/layouts/AuthenticatedLayout.vue'
import PortFolioBanner from '@components/PortFolio/PortFolioBanner/PortFolioBanner'
import FolderDocument from '@components/Documents/FolderDocument'
import FileDocument from '@components/Documents/FileDocument'
import FilesFields from '@components/DocumentsSorter/FilesFields'
import FilePickerArea from '@components/FilePicker/FilePickerArea'
import DocumentPanel from '@components/PortFolio/DocumentPanel/DocumentPanel'
import Split from '@components/Split/Split'
import SplitArea from '@components/Split/SplitArea'
import ContextMenu from '@components/ContextMenu/ContextMenu'
import trashUtils from '@utils/trash'

export default {
  name: 'Portfolio',
  components: {
    ContextMenu,
    SplitArea,
    Split,
    DocumentPanel,
    FilePickerArea,
    FilesFields,
    FileDocument,
    FolderDocument,
    PortFolioBanner,
    Layout
  },
  inject: ['mq'],
  data () {
    return {
      isContextMenuDisplayed: false,
      displayWaitingResponseSpinner: false,
      sort: {
        type: 'name',
        isOrderAsc: true,
        possibilities: [
          'name',
          'lastModifiedDate'
        ]
      }
    }
  },
  computed: {
    currentEntities () {
      // Why is this necessary to slice child arrays ?
      return JSON.parse(JSON.stringify(this.$store.state.files.folderContent))
    },
    currentFolderId () {
      return this.$store.state.files.currentFolderId
    },
    selectedFiles () {
      return this.$store.state.files.selectedFiles
    },
    selectedFile () {
      if (this.mq.phone || this.mq.tablet) {
        return this.selectedFiles[0]
      }

      // return the last clicked document if it is in selected files
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].id === this.$store.state.files.lastClickDocument.id) {
          return this.selectedFiles[i]
        }
      }

      // else return the last documents of selectedFiles list
      const index = this.selectedFiles.length - 1
      if (this.selectedFiles[index]) {
        return this.selectedFiles[index]
      }
      return undefined
    },
    sortedFiles () {
      return (this.currentEntities === undefined || this.currentEntities.files === undefined) ? []
        : this.currentEntities.files.slice().sort(utils.compare(this.sort.type, this.sort.isOrderAsc))
    },
    sortedSubFolders () {
      return (this.currentEntities === undefined || this.currentEntities.subFolders === undefined) ? []
        : this.currentEntities.subFolders.slice().sort(utils.compare(this.sort.type, this.sort.isOrderAsc))
    },
    allSortedDocuments () {
      return this.sortedSubFolders.concat(this.sortedFiles)
    },
    spacesStructure () {
      return spacesStructure
    },
    isDocumentPanelDisplayed () {
      return this.$store.state.files.isDocumentPanelDisplayed && this.selectedFile
    },
    isAContextMenuDisplayed () {
      return this.$store.state.contextMenu.isAContextMenuDisplayed
    },
    isMultiSelectionOptionsDisplayed () {
      return this.$store.state.files.isMultiSelectionOptionsDisplayed
    },
    isSpinnerDisplayed () {
      return this.$store.getters['currentActions/isInProgress']('importDocument') ||
        this.$store.getters['currentActions/isInProgress']('deleteDefinitely') ||
        this.$store.getters['currentActions/isInProgress']('paste') ||
        this.$store.getters['currentActions/isInProgress']('download') ||
        this.$store.getters['currentActions/isInProgress']('duplicate') ||
        this.$store.getters['currentActions/isInProgress']('move')
    }
  },
  watch: {
    'isDocumentPanelDisplayed' () {
      if (!this.mq.phone && !this.mq.tablet) {
        this.$refs.mySplit.sizes[0] = this.isDocumentPanelDisplayed ? 50 : 100 // to trigger size reactivity
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('fileFields/resetPrivateFields')
  },
  created () {
    // Watch route changes to react on progressionId change
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.params.folderId) {
          this.$store.dispatch('files/changeDirectory', this.$route.params.folderId)
        } else {
          this.$store.dispatch('files/getSpacesFolders')
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  mounted () {
    window.addEventListener('keydown', this.keyMonitor)
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.keyMonitor)
  },
  methods: {
    openSpaceContextMenu (event) {
      if (!this.isAContextMenuDisplayed) {
        this.isContextMenuDisplayed = true
        let options
        if (this.$store.state.clipboard.documentList.length === 0) { // remove paste option if no documents were copied
          options = contextMenus.privateSpace.slice(1)
        } else {
          options = [...contextMenus.privateSpace]
        }
        this.$store.dispatch('contextMenu/openContextMenu',
          {
            event: event,
            options: options
          })
      }
    },
    handleChosenOption (option) {
      this.$store.dispatch('contextMenu/closeMenus')
      switch (option) {
        case 'newFolder':
          this.$store.dispatch('modals/openCreateFolderModal')
          break
        case 'import':
          this.importDocumentFromWorkSpace()
          break
        case 'paste':
          this.$store.dispatch('clipboard/paste', this.currentFolderId)
          break
        default:
          console.error('unknown action for option', option)
      }
    },
    // keyboard shortcuts management
    keyMonitor: function (event) {
      // ctrl-A for 'All' selection
      if (event.ctrlKey && ((event.key === 'a') || (event.key === 'A'))) {
        event.preventDefault()
        this.selectAllFiles()
        // ctrl-arrow for multi-selection
      } else if (event.ctrlKey && ((event.key === 'ArrowDown') || (event.key === 'ArrowUp'))) {
        utils.selectCtrlDocument(this.$store, this.allSortedDocuments, this.$store.state.files.lastSelectedFile, event)
        // ctrl-C to copy
      } else if (event.ctrlKey && ((event.key === 'c') || (event.key === 'C'))) {
        this.$store.dispatch('clipboard/copy')
        // ctrl-X to cut
      } else if (event.ctrlKey && ((event.key === 'x') || (event.key === 'X'))) {
        this.$store.dispatch('clipboard/cut')
        // ctrl-V to paste
      } else if (event.ctrlKey && ((event.key === 'v') || (event.key === 'V'))) {
        this.$store.dispatch('clipboard/paste', this.currentFolderId)
        // ctrl-D to duplicate
      } else if (event.ctrlKey && ((event.key === 'd') || (event.key === 'D'))) {
        this.$store.dispatch('clipboard/duplicate')
        // F2 for renaming
      } else if ((event.key === 'F2')) {
        if (this.selectedFiles.length === 1) {
          this.$store.dispatch('modals/openRenameModal', this.selectedFiles[0])
        }
        // 'Suppr' for deletion
      } else if (event.key === 'Delete') {
        // const nextFileToSelect = utils.previousFile(this.allSortedDocuments, this.selectedFiles)
        this.$store.dispatch('warningModal/addWarning', {
          text: this.$t('router.views.Documents.Warning.deleteDocument'),
          lastAction: { fct: trashUtils.deleteDefinitely, params: [this.selectedFiles] }
        })
        // trashUtils.deleteDefinitely(this.$store, this.selectedFiles).then(() => {
        //   if (nextFileToSelect) {
        //     this.$store.dispatch('files/selectOneFile', nextFileToSelect)
        //   }
        // })
        // Arrows
      } else if ((event.key === 'ArrowDown') || (event.key === 'ArrowUp')) {
        utils.selectDocument(this.$store, this.allSortedDocuments, this.selectedFiles, event)
      }
    },
    importDocumentFromWorkSpace () {
      // Create an hidden inputFile
      const input = document.createElement('input')
      input.style.display = 'none'
      input.type = 'file'
      input.accept = '*/*'
      input.multiple = true
      input.onchange = e => {
        utils.returnAddedFiles(e, this.$store).then((files) => {
          if (files.length !== 0) {
            this.importDocument(files)
          } else {
            utils.alertNoFile()
          }
        })
        // TODO remove input from DOM?
      }

      // Click it
      input.click()
    },
    getEntityIndex (entityId) {
      return this.allSortedDocuments.map(item => item.id).indexOf(entityId)
    },
    importDocument (fileList) {
      filesUtil.importDocument(this.currentFolderId, fileList)
    },
    selectAllFiles () {
      this.$store.dispatch('files/selectAllFiles')
    },
    shiftSelect (file) {
      const listFilesToSelect = utils.selectBetween(this.allSortedDocuments, this.$store.state.files.lastSelectedFile, file)
      this.$store.dispatch('files/selectManyFiles', listFilesToSelect)
    },
    handleSort (type) {
      if (type === this.sort.type) {
        this.sort.isOrderAsc = !this.sort.isOrderAsc
      } else {
        this.sort.type = type
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.portfolio {
  height: calc(100% - #{$desktop-search-bar-height});
}

.phone-portfolio {
  height: calc(100% - #{$mobile-search-bar-height});
}

.phone-split {
  height: 100%;

  .document-list {
    padding-top: 0;
  }
}

.entities {
  overflow-y: auto;
}

.documents-body {
  height: calc(100% - #{$portfolio-banner-height});
}

.documents-body-shrink {
  height: calc(100% - (#{$portfolio-banner-height} + #{$portfolio-multi-selection-height}));
  .document-list {
    padding-top: 5px;
  }
}

.document-list {
  width: 100%;
  height: 100%;
  padding-top: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;

  .drop-icon {
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* disable text selection */
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none; /* CSS3 (little to no support) */

    .icon-container {
      cursor: pointer;
      height: 97px;
      width: 97px;
      border-radius: 49px;
      background-color: $dark-white-bg;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 25px;
      }
    }

    p {
      font-size: 0.8125em;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      cursor: default;
      //text-align: center;
    }
  }
}

.document-panel {
  height: 100%;
}

.phone-document-panel {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  padding: 0 10px;

  &.tablet-document-panel {
    left: $side-menu-collapsed-width;
    width: calc(100vw - #{$side-menu-collapsed-width});
  }
}

</style>
