<template>
  <div data-test="delete-confirm-modal">
    <Window
      :modal="true"
      @close="onClose"
    >
      <template #header>
        <span>{{ $t('Modals.DeleteConfirmModal.advertisingText') + ' ' + formattedEntities + ' ?' }}</span>
      </template>

      <template #body>
        <div class="window-body">
          <p class="free-size-label">
            {{ formattedFreeSize }}
          </p>
          <i class="fas fa-exclamation-triangle" />
          <p class="description-text">
            {{ $t('Modals.DeleteConfirmModal.descriptionText') }}
          </p>
        </div>
      </template>

      <template #footer>
        <div class="window-footer">
          <Button
            data-test="cancelButton"
            class="cancel-button"
            :label="$t('Modals.Commons.cancelButton')"
            @click="onClose"
          />
          <Button
            data-test="confirmButton"
            class="confirm-button"
            :label="$t('Modals.DeleteConfirmModal.confirmButtonLabel')"
            @click="confirm"
          />
        </div>
      </template>
    </Window>
  </div>
</template>

<script>

import formatters from '@/utils/formatters.util'

export default {
  name: 'DeleteConfirmModal',
  props: {
    entitiesToDefinitelyDelete: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    }
  },
  emits: ['confirmDeletion', 'close'],
  computed: {
    formattedEntities () {
      if (this.entitiesToDefinitelyDelete.length === 1) {
        return '"' + this.entitiesToDefinitelyDelete[0].name + '"'
      } else {
        return this.entitiesToDefinitelyDelete.length + ' ' + this.$t('Modals.DeleteConfirmModal.elementLabel')
      }
    },
    formattedFreeSize () {
      let freeSize = 0
      for (let i = 0; i < this.entitiesToDefinitelyDelete.length; ++i) {
        freeSize += this.entitiesToDefinitelyDelete[i].size
      }
      return this.$t('Modals.DeleteConfirmModal.freeSizePart1') +
        formatters.formatSize(freeSize) +
        this.$t('Modals.DeleteConfirmModal.freeSizePart2')
    }
  },
  methods: {
    confirm () {
      this.$emit('confirmDeletion')
      this.onClose()
    },
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  .window-body{
    padding: 10px;
  }

  .free-size-label{
    padding-bottom: 10px;
  }

  .fa-exclamation-triangle{
    font-size: 50px;
  }

  .description-text{
    display: inline;
    vertical-align: 12px
  }

  .window-footer{
    display: flex;
    justify-content: space-evenly;
  }

  .cancel-button{
    flex-grow: 1;
    height: 35px;
    background-color: grey;
  }

  .confirm-button{
    flex-grow: 1;
    height: 35px;
    background-color: red;
  }
</style>
