<template>
  <iframe
    :src="src"
    class="media"
  />
</template>

<script>
export default {
  name: 'PDFDocument',
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .media {
    width: 100%;
    flex: 1;
  }
</style>
