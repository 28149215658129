<template>
  <div class="document-data">
    <!-- Name -->
    <!--    <div class="field">-->
    <!--      <span-->
    <!--        v-t="'DocumentDetails.name'"-->
    <!--        class="content-label"-->
    <!--      />-->
    <!--      <span class="content-description"> {{ formattedName }} </span>-->
    <!--    </div>-->

    <!-- Type -->
    <div class="field">
      <span
        v-t="'DocumentDetails.type'"
        class="content-label"
      />
      <span class="content-description"> {{ formattedType }} </span>
    </div>

    <!-- Size -->
    <div class="field">
      <span
        v-t="'DocumentDetails.size'"
        class="content-label"
      />
      <span class="content-description"> {{ formattedSize }} </span>
    </div>

    <!-- LastModifiedDate -->
    <div class="field">
      <span
        v-t="'DocumentDetails.lastModifiedDate'"
        class="content-label"
      />
      <span class="content-description"> {{ formattedLastModifiedDate }} </span>
    </div>
  </div>
</template>

<script>
import formatters from '@/utils/formatters.util'

export default {
  name: 'DocumentPanelData',
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedName () {
      return formatters.formatName(this.document.name, this.document.type === 'File')
    },
    formattedType () {
      return formatters.formatType(this.document.type, this.document.extension)
    },
    formattedSize () {
      return formatters.formatSize(this.document.size)
    },
    formattedLastModifiedDate () {
      return formatters.formatDate(this.document.lastModifiedDate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.document-data {
  width: 100%;
}

.field{
  padding-top: 10px;
  display: flex;
  color: $color-text;
}

.content-label{
  flex: 1;
}

.content-description{
  flex: 1;
}

</style>
