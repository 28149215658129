<template>
  <div
    class="option"
    :class="{'first': isFirst}"
    @click="emitClick"
  >
    <div class="title">
      {{ option.title }}
    </div>
    <div class="icon">
      <img
        :src="option.icon"
        :alt="option.title"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuOption',
  props: {
    option: {
      type: Object,
      required: true
    },
    isFirst: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  methods: {
    emitClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.option {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid $color-border-menu;

  .title {
    margin-top: 5px;
    padding: 10px;
    margin-bottom: 5px;
    font-size: 0.9375em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 23px;
  }

  .icon {
    margin-left: auto;
    height: 33px;
    width: 33px;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-white-bg;

    img {
      width: 15px;
    }
  }

  &:hover {
    .title {
      color:  $color-hover-cadyco;
    }

    .icon {
        background-color: $color-hover-cadyco;
    }
  }

  &.first {
    border-top: 0;
  }
}

</style>
