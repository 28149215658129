<template>
  <GenericDocument
    :file="fileObj"
    :quick-options="computedQuickOptions"
    :display="display"
    :display-date="displayDate"
    :is-draggable="isDraggable"
    :dark="dark"
    @open="openFile"
    @click="handleClick"
    @dblclick="openFile"
    @shiftSelect="dispatchEvent"
  />
</template>

<script>
import GenericDocument from '@/components/Documents/GenericDocument/GenericDocument'
import { icons } from '@/constants/icons'
import spacesStructure from '@/utils/spaces.structure'

export default {
  name: 'FileDocument',
  components: { GenericDocument },
  inject: ['mq'],
  myIcons: icons,
  props: {
    file: {
      type: Object,
      required: true,
      validator: function (obj) {
        return (typeof obj.id === 'string') &&
               (typeof obj.name === 'string' && obj.name.length > 0)
      }
    },
    display: { // TODO validator on enumerate type
      type: String,
      default: 'list'
    },
    displayDate: {
      type: Boolean,
      default: true
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    quickOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  emits: ['shiftSelect'],
  computed: {
    isSelected () {
      for (let i = 0; i < this.$store.state.files.selectedFiles.length; ++i) {
        if (this.file.id === this.$store.state.files.selectedFiles[i].id) {
          return true
        }
      }
      return false
    },
    isMultiSelectionOptionsDisplayed () {
      return this.$store.state.files.isMultiSelectionOptionsDisplayed
    },
    fileObj () {
      const obj = this.file
      obj.icon = this.fileIcon
      return obj
    },
    fileIcon () {
      if (this.$options.myIcons.extensions[this.file.extension] === undefined) {
        return this.$options.myIcons.file
      } else {
        return this.$options.myIcons.extensions[this.file.extension]
      }
    },
    computedQuickOptions () {
      let computedQuickOptions = []
      if (this.file.isShortcut) {
        switch (this.file.sourceSpace) {
          case this.$t('AppCommonsLabels.Spaces.private'):
          case 'private':
            computedQuickOptions = [...spacesStructure.private.documentQuickOptions]
            break
          default:
            console.error('unknown sourceSpace ', this.file.sourceSpace)
            break
        }
      } else {
        computedQuickOptions = [...this.quickOptions]
      }

      return computedQuickOptions
    }
  },
  methods: {
    enterAction () {
      if (this.isSelected) {
        this.openFile()
      }
    },
    openFile () {
      this.$store.dispatch('files/openDocumentPanel')
    },
    handleClick () {
      if (this.mq.phone || this.mq.tablet) {
        if (this.isMultiSelectionOptionsDisplayed) {
          this.$store.dispatch('files/updateCtrlSelectedFiles', this.file)
        } else {
          this.$store.dispatch('files/selectOneFile', this.file)
          this.openFile()
        }
      }
    },
    dispatchEvent (file) {
      this.$emit('shiftSelect', { id: file.id, name: file.name })
    }
  }
}
</script>

<style scoped>

</style>
