import axios from 'axios'
import constants from '@/constants/appConstants'
import { getCookie } from '@/utils/browser.util'

export default {
  deleteDefinitely,
  moveToTrash,
  emptyTrash,
  restore
}

const TRASH_PATH = '/documents.trash'

/**
 * Delete definitely the specified entities from trash
 */
function deleteDefinitely (folderIdArray, fileIdArray) {
  return axios.get(constants.JSON_WS_URL + TRASH_PATH + '/delete-documents', {
    params: {
      p_auth: getCookie('pauth'),
      folderIdArray: JSON.stringify(folderIdArray),
      fileIdArray: JSON.stringify(fileIdArray)
    }
  }).then(response => response.data)
}

/**
 * Put the specified entities to trash, warn the user if a file is open in edition,
 * force = true means the file is open in edition but the user want to delete it anyway
 */
function moveToTrash (folderIdArray, fileIdArray, force) {
  return axios.get(constants.JSON_WS_URL + TRASH_PATH + '/move-to-trash', {
    params: {
      p_auth: getCookie('pauth'),
      folderIdArray: JSON.stringify(folderIdArray),
      fileIdArray: JSON.stringify(fileIdArray),
      force: force
    }
  }).then(response => response.data)
}

/**
 * Delete definitely all the entities of trash
 */
function emptyTrash () {
  return axios.get(constants.JSON_WS_URL + TRASH_PATH + '/empty-trash-folder', {
    params: {
      p_auth: getCookie('pauth')
    }
  }).then(response => response.data)
}

/**
 * Restore the specified entity from trash,
 * recreatePath = true means restore document to their original path, else, restore on private root folder
 */
function restore (folderIdArray, fileIdArray, recreatePath) {
  return axios.get(constants.JSON_WS_URL + TRASH_PATH + '/restore', {
    params: {
      p_auth: getCookie('pauth'),
      folderIdArray: JSON.stringify(folderIdArray),
      fileIdArray: JSON.stringify(fileIdArray),
      recreatePath: recreatePath
    }
  }).then(response => response.data)
}
