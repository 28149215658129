<template>
  <div class="container">
    <MenuOption
      v-for="(option, index) in options"
      :key="index"
      :is-first="index===0"
      :option="option"
      @click="selectOption(option)"
    />
  </div>
</template>

<script>
import contextMenus from '@/utils/contextMenus'
import MenuOption from '@components/PortFolio/MenuOption'
import utils from '@utils/utils'
import trashUtils from '@utils/trash'
import navigationService from '@/api/documents/navigation.service'

export default {
  name: 'CurrentFolderOptions',
  components: { MenuOption },
  props: {
    currentFolder: {
      type: Object,
      required: true
    }
  },
  emits: ['close'],
  computed: {
    options () {
      const options = utils.cloneArrayOfObject(contextMenus.privateSpaceFolderMenu)
      utils.removeMenuOptionIfExist(options, 'move')
      utils.removeMenuOptionIfExist(options, 'duplicate')
      return options
    }
  },
  mounted () {
    window.addEventListener('click', this.clickOutside)
    window.addEventListener('contextmenu', this.clickOutside)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.clickOutside)
    window.removeEventListener('contextmenu', this.clickOutside)
  },
  methods: {
    clickOutside (e) {
      const self = this
      if (self.$el && !self.$el.contains(e.target)) {
        this.close()
      }
    },
    close () {
      this.$emit('close')
    },
    selectOption (option) {
      switch (option.name) {
        case 'rename':
          this.$store.dispatch('modals/openRenameModal', this.currentFolder)
          break
        case 'delete':
          this.$store.dispatch('warningModal/addWarning', {
            text: this.$t('router.views.Documents.Warning.deleteDocument'),
            lastAction: { fct: trashUtils.deleteDefinitely, params: [[this.currentFolder]] }
          })
          break
        case 'download':
          this.downLoad()
          break
        default:
          console.error('Unknown option', option)
      }
      this.close()
    },
    downLoad () {
      // TODO move to utils
      this.displaySpinner = true
      navigationService.downloadFolder(this.currentFolder.id).then((data) => {
        this.displaySpinner = false
        if (data.success) {
          const url = data.downloadURL

          const a = document.createElement('a')
          a.style.display = 'none'
          a.download = this.currentFolder.name // don't works on Internet Explorer and IOS' safari
          a.href = url
          a.click()
        } else {
          console.error('error when creating archive for folder')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  width: 220px;
  z-index: 900;
  background-color: white;
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  padding: 0 20px;
}
</style>
