<template>
  <div
    class="option"
    :class="{'active': isActive}"
    @click="click"
  >
    <img
      :src="option.icon"
      :alt="option.name"
    >
  </div>
</template>

<script>
export default {
  name: 'SelectionOptionItem',
  props: {
    option: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  emits: ['selectOption'],
  methods: {
    click () {
      if (this.isActive) {
        this.$emit('selectOption')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.option {
  height: 33px;
  width: 33px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  &:not(.active) {
    opacity: 0.4;
  }
}

</style>
