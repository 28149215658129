<template>
  <div
    class="document-header"
    :class="{'phone' : mq.phone}"
  >
    <div class="first-line">
      <div class="name">
        <div class="icon-container">
          <img
            v-if="fileIconIsImage"
            class="img-icon"
            :src="document.icon"
            alt="document icon"
          >
          <BaseIcon
            v-else
            class="base-icon"
            :name="[iconPrefix, document.icon]"
          />
        </div>
        <div class="name-container">
          <div
            class="name-label"
            :title="document.name"
          >
            {{ document.name }}
          </div>
          <div
            v-if="document.extension !== 'cao'"
            class="size-label"
          >
            {{ formattedSize }}
          </div>
        </div>
      </div>
      <div
        class="close"
        @click="closePanel"
      >
        <img
          src="@assets/cross_white.png"
          alt="close panel"
        >
      </div>
    </div>
    <SelectionOptions
      v-if="mq.phone || mq.tablet"
      :is-select-all-option-displayed="false"
    />
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
import formatters from '@utils/formatters.util'
import SelectionOptions from '@components/PortFolio/PortFolioBanner/selectionOptions'
export default {
  name: 'DocumentPanelHeader',
  components: { SelectionOptions, BaseIcon },
  inject: ['mq'],
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  computed: {
    fileIconIsImage () {
      // TODO: find a better way to separate img and font-awesome icons
      return this.document.icon.includes('.') || this.document.icon.includes(':') // if icon contains extension (like folder.svg) it's not a font-awesome
    },
    formattedSize () {
      return formatters.formatSize(this.document.size)
    },
    iconPrefix () {
      if (this.document.type === 'File') {
        if (this.document.icon === 'code') {
          return 'fas'
        } else {
          return 'far'
        }
      } else {
        return 'fas'
      }
    }
  },
  methods: {
    closePanel () {
      if (this.mq.phone) {
        this.$store.dispatch('files/cleanSelectedFiles')
      }
      this.$store.dispatch('files/closeDocumentPanel')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.document-header {
  width: 100%;
  color: $color-cadyco-dark-text;

  .first-line {
    width: 100%;
    height: $portfolio-banner-height;
    display: flex;
    align-items: center;
  }
}

.name {
  display: flex;
  align-items: center;
  width: calc(100% - 48px);

  .icon-container {
    width: 30px;
    margin-right: 15px;
    display: flex;
    justify-content: center;

    .img-icon {
      width: 30px;
      height: 30px;
    }

    .base-icon {
      font-size: 27px;
      color: #777777;
    }
  }

  .name-container {
    height: 100%;
    width: calc(100% - 45px);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name-label {
      width: 100%;
      font-size: 1.5em;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 28px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .size-label {
      font-size: 0.6875em;
      letter-spacing: 0;
      line-height: 16px;
    }
  }
}

.close {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $light-grey-bg;

  &:hover {
    background-color: $color-hover-cadyco;
  }

  img {
    width: 14px;
  }
}

.icon {
  margin-right: 1rem;
}

.img-icon {
  width: 25px;
  height: 25px;
}

.times {
  /*margin: 10px;*/
  cursor: pointer;
}

.phone {
  .first-line {
    height: 75px;
  }

  .name {
    .name-container {
      .name-label {
        font-size: 1.125em;
      }
    }
  }
}
</style>
