<template>
  <GenericDocument
    class="folder-document"
    :class="{ 'active': isActive }"
    :file="fileObj"
    :quick-options="computedQuickOptions"
    :dark="dark"
    :display="display"
    :display-date="displayDate"
    :is-draggable="isDraggable"
    @click="clickOnFolder"
    @dblclick="changeDir"
    @shiftSelect="dispatchEvent"
    @open="changeDir"
    @dragover="setActive"
    @dragleave="cancelActive"
    @drop="dropFile"
  />
</template>

<script>
import GenericDocument from '@/components/Documents/GenericDocument/GenericDocument'
import { icons } from '@/constants/icons'
import spacesStructure from '@/utils/spaces.structure'

export default {
  name: 'FolderDocument',
  components: { GenericDocument },
  inject: ['mq'],
  myIcons: icons,
  props: {
    folder: {
      type: Object,
      required: true,
      validator: function (obj) {
        return (typeof obj.id === 'string') &&
               (typeof obj.name === 'string' && obj.name.length > 0)
      }
    },
    display: {
      type: String,
      default: 'list'
    },
    displayDate: {
      type: Boolean,
      default: true
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    quickOptions: {
      type: Array,
      default: function () {
        return []
      }
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  emits: ['shiftSelect'],
  data () {
    return {
      isActive: false,
      isDragging: false
    }
  },
  computed: {
    isThereInternDocumentDrag () {
      return this.$store.state.misc.isThereDocumentDrag
    },
    isMultiSelectionOptionsDisplayed () {
      return this.$store.state.files.isMultiSelectionOptionsDisplayed
    },
    isSelected () {
      for (let i = 0; i < this.$store.state.files.selectedFiles.length; ++i) {
        if (this.folder.id === this.$store.state.files.selectedFiles[i].id) {
          return true
        }
      }
      return false
    },
    draggedFiles () {
      return this.$store.state.misc.draggedEntities
    },
    isDragged () {
      for (let i = 0; i < this.draggedFiles.length; ++i) {
        if (this.folder.id === this.draggedFiles[i].id) {
          return true
        }
      }
      return false
    },
    fileObj () {
      const obj = this.folder
      obj.icon = this.isActive ? this.$options.myIcons.folderOpen : this.$options.myIcons.folder
      return obj
    },
    computedQuickOptions () {
      let computedQuickOptions = []
      if (this.folder.isShortcut) {
        switch (this.folder.sourceSpace) {
          case this.$t('AppCommonsLabels.Spaces.private'):
          case 'private':
            computedQuickOptions = [...spacesStructure.private.documentQuickOptions]
            break
          default:
            console.error('unknown sourceSpace ', this.folder.sourceSpace)
            break
        }
      } else {
        computedQuickOptions = [...this.quickOptions]
      }
      return computedQuickOptions
    }
  },
  methods: {
    cancelHandlers (e) {
      e.preventDefault()
      e.stopPropagation()
    },
    setActive (e) {
      if (this.isThereInternDocumentDrag && !this.isDragged) {
        this.isActive = true
        this.cancelHandlers(e)
      }
    },
    cancelActive (e) {
      if (this.isThereInternDocumentDrag && !this.isDragged) {
        this.isActive = false
        this.cancelHandlers(e)
      }
    },
    dropFile (e) {
      if (this.isThereInternDocumentDrag && !this.isDragged) {
        this.cancelActive(e)
        // dropFileAction
        this.$store.dispatch('clipboard/drop', {
          entities: JSON.parse(e.dataTransfer.getData('entitiesToDrop')),
          folder: this.folder
        })
      }
    },
    dispatchEvent (file) {
      this.$emit('shiftSelect', { id: file.id, name: file.name })
    },
    changeDir () {
      this.$router.push({ name: 'portfolio', params: { folderId: this.folder.id } })
      if (!this.mq.phone && !this.mq.tablet) {
        this.$store.dispatch('files/selectOneFile', this.folder)
      }
    },
    clickOnFolder () {
      if (this.mq.phone || this.mq.tablet) {
        if (this.isMultiSelectionOptionsDisplayed) {
          this.$store.dispatch('files/updateCtrlSelectedFiles', this.folder)
        } else {
          this.changeDir()
        }
      } else {
        this.$store.dispatch('files/openDocumentPanel')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@design';

  .folder-document {
    &.active {
      color: $color-light-text;
      background-color: $color-drop-bg;
    }
  }

</style>
