<template>
  <div class="container">
    <Map
      class="media"
      :object-list="objectList"
    />
  </div>
</template>

<script>
import Map from '@components/Map/Map'

export default {
  name: 'CadycoDocument',
  components: { Map },
  computed: {
    objectList () {
      return this.$store.state.files.displayedCadycoFiles
    }
  }
}
</script>

<style scoped>
  .container {
    width: 100%;
    flex: 1;
    border: 1px solid #0B3C5F;
  }

  .media {
    width: 100%;
    height: 100%;
  }
</style>
