<template>
  <div class="document-panel">
    <DocumentPanelHeader
      :document="document"
    />
    <DisplayFile
      v-if="document.type === 'File'"
      :file="document"
      class="media"
    />
    <DocumentPanelData
      v-else
      class="data"
      :document="document"
    />
  </div>
</template>

<script>
import DisplayFile from '@components/Documents/DisplayDocument/DisplayFile'
import DocumentPanelData from '@components/PortFolio/DocumentPanel/DocumentPanelData'
import DocumentPanelHeader from '@components/PortFolio/DocumentPanel/DocumentPanelHeader'
export default {
  name: 'DocumentPanel',
  components: { DocumentPanelHeader, DocumentPanelData, DisplayFile },
  props: {
    document: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

  .document-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $dark-white-bg;
    padding: 0 20px 15px 20px;
    /*border-top: 1px solid #BDBDBD;*/
    /*border-left: 1px solid #BDBDBD;*/
    /*border-right: 1px solid #BDBDBD;*/
  }

</style>
