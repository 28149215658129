<template>
  <div class="mobile-grid-document">
    <i
      class="icon"
      :class="file.icon"
    />
    <p
      class="file-name"
      :class="{ 'new': file.isNew }"
    >
      {{ file.name }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'MobileGridDocument',
  props: {
    file: {
      type: Object,
      required: true,
      validator: function (obj) {
        return (typeof obj.id === 'number') &&
          (typeof obj.name === 'string' && obj.name.length > 0) &&
          (typeof obj.icon === 'string' && obj.icon.length > 0)
      }
    }
  }
}
</script>

<style scoped>
  .mobile-grid-document{
    display: inline-block;
    padding: 2px 3px;
    margin: 5px;
    text-align: center;
    background-color: white;
    border: 1px solid rgba(50, 50, 255, 0.5);
  }

  .icon{
    font-size: 30px;
  }

  .new{
    font-weight: bold;
  }

</style>
